.mainFragment {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.breathingIcon{
    animation: changeColor 0.5s infinite alternate;
}
.successIcon{
    color: greenyellow;
}
.errorIcon{
    color:red;
}

@keyframes changeColor {
    from {
        color: white;
    }
    to {
        color: greenyellow;
    }
}