.root {
    display: flex;
    overflow: hidden;
    background-image: url("../../../public/static/png/appCurvyLines.png");
    background-position: center;
    background-size: cover;
}
.container {
    margin-top: 15px;
    margin-bottom: 30px;
    display: flex;
    position: relative;

}
.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
}
.image {
    height: 100px;
}
.title {
    margin: 16px 0px !important;
}
.curvyLines {
    pointer-events: none;
    position: absolute;
    top: 0px;
    left:-20%;
}
