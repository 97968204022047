.main_screen {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    max-height: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
}
div.center_cropped {
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: absolute;
}
div.center_cropped video {
    height: 120%;
    min-width: 120%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}
.mainLogo {
    height: 0%;
    width: 0%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    opacity: 50%;
}
.minimize {
    position: relative;
    animation: minimize 1000ms forwards;
    animation-delay: 2s;
}
@keyframes minimize {
    from {
        top: 0px;
        left: 0vw;
        height: 100vh;
        width: 100vw;
    }
    to {
        top: 50vh;
        left: 50vw;
        height: 0vh;
        width: 0vw;
    }
}
.maximize {
    animation: maximize 1000ms forwards;
}
@keyframes maximize {
    from {
        top: 50%;
        height: 0%;
        width: 0%;
    }
    to {
        top: 25%;
        height: 50%;
        width: 50%;
    }
}
.hidden {
    display: none !important;
}

.topImg {
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0px;
}

.topImg > img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}

.main_content{
    display: flex;
    flex-direction: column;
    background-color: transparent;
    z-index: 2;
    padding: 80px 16px 0px 16px;
    background-color: rgba(0, 128, 0, 0.383);
    min-height: 100vh;
    box-sizing: border-box;

}

.primaryHeader{
    background-color: rgba(255, 0, 0, 0.545);
    min-height: 80vh;
    box-sizing: border-box;
}