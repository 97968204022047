.breathingIcon{
    animation: changeColor 0.5s infinite alternate;
}
.successIcon{
    color: greenyellow;
}
.neutralIcon{
    color:black;
}
.hidden{
    display: none;
}
@keyframes changeColor {
    from {
        color: black;
    }
    to {
        color: greenyellow;
    }
}