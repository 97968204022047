.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
}
.button {
    height: auto;
    padding: 4px 8px;
}
.link {
    margin-top: 8px;
    margin-bottom: 8px;
}
.buoy {
    width: 60px;
}
