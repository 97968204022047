.background {
    background-image: url("./assets/bgImg.jpg");
    background-position: center;
}
.button {
    min-width: 200;
}
.sh5 {
    box-sizing: border-box;
    margin: 40px 0px;
}
.more{
    box-sizing: border-box;
    margin-top: 16px;
}
